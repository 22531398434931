(function($){
  /* mobile menu */
  const $navbarBurgers = $('.navbar-burger');
  $navbarBurgers.each(function(index, element) {
    var $this = $(this);
    var target = $this.data('target');
    var $target = $('#'+target);

    $this.on('click', function(event) {
      event.preventDefault();
      $this.toggleClass('is-active');
      $target.toggleClass('is-active');
    });
  });

  /* smooth scoll */
  $('body').on('click', '#menu a', function(event) {
    var url = $(this).attr('href');
    var target = url.substring(url.indexOf('#'));
    var $target = $(target);
    
    if ( $target.length > 0 ) {
      event.preventDefault();

      $('html, body').animate({
          scrollTop: $target.offset().top
      }, 250);
    }
  });
})(jQuery);
